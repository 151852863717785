<template>
    <div>    
        <antModal v-model="visible" :title="eventTitle" :closable="false" :maskClosable="false" :centered="true" :footer="null" :width="850">
            <ValidationObserver ref="deleteEvent" tag="form">
                
                    <b-row>
                        <b-col><b>Locación: </b>{{  nameLocation( selectedLocation ) }}</b-col>
                        <b-col><b>Evento: </b> {{ selectedEvent.nombreEvento }}</b-col>
                        <b-col><b>Fecha: </b> {{ selectedEvent.startDate }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="text-center mt-4" v-if="isLoadingLogs">							
                                <b-spinner label="Loading..." variant="success"/><br>
                                <strong>Cargando registros </strong>
                            </div>                         
                            <div class="mt-4" v-else >
                                <div v-if="logs.length > 0">
                                    <div
                                        class="card card-body mb-2 border-card-logger"
                                        v-for="(log, index) in paginatedLogs"
                                        :key="index"
                                    >
                                        <div
                                            class="head-collapse"
                                            @click="toggleCollapse(index)"
                                            :aria-controls="'collapseLog' + index"
                                        >
                                            <div class="info-head">
                                                <div class="description">
                                                    <p>
                                                        <b>{{ log.user.name }}</b> ha realizado un cambio
                                                        <b class="spanSmall blueColor"> {{ log.timeAgo }}</b>
                                                        
                                                    </p>
                                                    <p><b>Grupo:</b> {{ log.actualData.grupo }}</p>
                                                   
                                                    <p><b>Fecha:</b> {{ log.createDateHuman }}</p>
                                                </div>
                                            </div>
                                            <div class="arrow-collapse">
                                                <span class="badge badge-info mr-2"> {{log.action.name}} </span>                                        
                                                <i :class="collapsedStates[index] ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i><br />
                                            </div>
                                        </div>
                                
                                        <b-collapse :id="'collapseLog' + index" v-model="collapsedStates[index]"  class="mt-1">
                                            <div class="table-responsive mt-3">
                                                <table class="table mb-0 table-bordered">
                                                    <thead class="thead-dark">
                                                        <tr style="font-size: .9em">
                                                            <th>Campo</th>
                                                            <th>Dato Anterior</th>
                                                            <th>Dato Actual</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style="font-size: .85rem">
                                                        <tr>
                                                            <td>
                                                                <b>Evento</b>                        
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.nombreEvento : '--'
                                                                }}
                                                            </td>
                                                            <td>                                                                
                                                                {{ log.actualData ? log.actualData.nombreEvento :  '--' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Adultos</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.adult: '--'
                                                                }}                                                              
                                                            </td>
                                                            <td>{{ log.actualData ? log.actualData.adult : '--' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Niños</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.child: '--'
                                                                }}
                                                            </td>
                                                            <td>{{ log.actualData ? log.actualData.child : '--' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Infantes</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.infant: '--'
                                                                }}
                                                            </td>
                                                            <td>{{ log.actualData ? log.actualData.infant : '--' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Fecha Inicio</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.startDate: '-'
                                                                }}
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.startTime: '-'
                                                                }}                                                                
                                                            </td>
                                                            <td>
                                                                {{ log.actualData ? log.actualData.startDate : '--' }}
                                                                {{ log.actualData ? log.actualData.startTime : '--' }} 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Fecha Fin</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.endDate: '-'
                                                                }}
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.endTime : '-'
                                                                }}                                                                                                                            
                                                            </td>
                                                            <td>
                                                                {{ log.actualData ? log.actualData.endDate : '--' }}
                                                                {{ log.actualData ? log.actualData.endTime : '--' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Locación</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.locacion : '--'
                                                                }}                                                              
                                                            </td>
                                                            <td>{{ log.actualData ? log.actualData.locacion  : '--' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Montaje</b>
                                                            </td>
                                                            <td>                                                                
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.montaje : '--'
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{ log.actualData ? log.actualData.montaje : '--' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>Backup</b>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    log.action.name != 'Nuevo' && log.previousData ?
                                                                    log.previousData.backup : '--'
                                                                }}                                                                
                                                            </td>
                                                            <td>
                                                                {{ log.actualData ? log.actualData.backup : '--' }}
                                                            </td>
                                                        </tr>
                                                       
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-collapse>
                                    </div> 
                                    
                                    <b-pagination
                                        v-show="logs.length > pageSize"
                                        v-model="currentPage"
                                        :total-rows="logs.length"
                                        :per-page="pageSize"
                                        align="right"
                                        class="mt-3"
                                    ></b-pagination>

                                </div>
                                <div class="text-center" v-else>
                                    <p class="text-center">
                                        <b> No hay registros disponibles </b> 
                                    </p>
                                </div>
                            </div>
                            
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col>
                            <b-button variant="danger" @click="closeModal">Cerrar</b-button>
                        </b-col>
                      
                    </b-row>                       
                
          </ValidationObserver>                                                              
      </antModal>
    </div>
</template>
<script>
  
import * as moment from 'moment'
import { mapActions, mapState, mapMutations} from 'vuex'
  
export default {
    name: 'EditEvent',
    props:{
        visible: {
            type: Boolean,
            required: true
        }, 
        isLoadingLogs:{
            type: Boolean,
            required: true
        },
        selectedEvent:{
            type:Object,
            required: true
        },
        logs: {
            type: Array,
            required: true
        }
    },
    mounted(){  
        this.collapsedStates = this.logs.map(() => false)      
    },
    data() {
        return {
            loading: false, 
            nombreEvento: this.selectedEvent.nombreEvento,    
            deleteReason: '',
            isDeletingEvent: false,
            isCollapsed: false,
            collapsedStates: [],
            currentPage: 1,
            pageSize: 5,
        }
    },   
    computed: {        
        ...mapState('init', ['locations','events','idUser']),                    
        ...mapState('calendar', ['groups','selectedLocation','isOpenModalDeleteEvent','eventsInSelectedDate','isLoadingGroups']),
        eventTitle(){                         
            return `Registro de cambios`
        },            
        totalPages() {
            return Math.ceil(this.logs.length / this.pageSize);
        },
        paginatedLogs() {
            const start = (this.currentPage - 1) * this.pageSize;
            return this.logs.slice(start, start + this.pageSize);
        }                          
    },
    methods: {
        moment,
        ...mapActions('calendar',['fetchGroupsSchedulerByName','deleteEvent','getEventsAndTotals','fetchGroupsScheduler']),
        ...mapMutations('calendar',['unsetSelectedEvent','setEventsInSelectedDate','setVisibilityModalDeleteEvent']),
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },                           
        closeModal(){
            this.$emit('closeModalLogsEvent')
        },   
        toggleCollapse(index) {
            this.$set(this.collapsedStates, index, !this.collapsedStates[index]);
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }         
    },
  }
  
</script>

<style scoped>
    .card-log {
        margin-bottom: 20px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    }

    .card-log-header {
        background-color: #FFFFFF;
        padding: 10px;
        cursor: pointer;
    }

    .card-log-content {
        display: none;
        padding: 10px;
        opacity: 0; /*Inicialmente oculto */
        transition: opacity 0.3s ease; /* Transición suave de 0.3 segundos */
    }

    .card-log-content.open {
        display: block;
        opacity: 1; 
    }


    .head-collapse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .info-head {
        display: flex; /* Establece la visualización flexbox */
        align-items: center;
    }

    .description {
        flex-grow: 1; /* Permite que la descripción ocupe todo el espacio disponible */
    }
    .changes-card {
        padding-top: 1rem;
    }

    .description p, .changes-card p {
        margin: 0; /* Elimina el margen predeterminado de los párrafos */
    }

    .changes-card p:nth-child(1) {
        font-weight: bold;
    }

    .description p:nth-child(1){
        font-size: .85rem;
    }

    .spanSmall{
        font-size: .8rem;
        color: #475467;
    }

    .blueColor{
        color:#17a2b8 ;
    }

    .info-pagination{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        width: 100%;
    }

    .pagination-logs  {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-reset {
        color: rgb(255, 255, 255);
    }

    .border-card-logger {
        border: 2px solid rgba(0, 0, 0, .125);
    }



</style>