export function setLocationsData(state, locations) {
    state.locations = locations
}
export function setSelectedHotelFilter(state, idHotel) {
    state.selectedHotelFilter = idHotel
}
export function setSelectedLocationFilter(state, idLocation) {
    state.selectedLocationFilter = idLocation
}
export function setGroupTypes(state, groupTypes) {
    state.groupTypes = groupTypes
}

export function setIdUser(state, idUser) {
    state.idUser = idUser
}

export function setPermissionsUser(state, permissions) {
    state.permissionsUser = permissions
}

export function setHotels(state, hotels) {
    state.hotels = hotels
}

export function setLanguages(state, languages) {
    state.languages = languages
}

export function setAgencies(state, agencies) {
    state.agencies = agencies
}

export function setAgents(state, agents) {
    state.agents = agents
}

export function setAgencySelected(state, agencySelected) {
    state.agencySelected = agencySelected
}

export function setCoordinators(state, coordinators) {
    state.coordinators = coordinators
}

export function setInitError(state, error) {
    state.initError = error
}

export function setLoadingLocations(state, bool) {
    state.isLoadingLocations = bool
}

export function setLoadingGroupTypes(state, bool) {
    state.isLoadingGroupTypes = bool
}

export function setLoadingGroups(state, bool) {
    state.isLoadingGroups = bool
}

export function setLoadingCoordinators(state, bool) {
    state.isLoadingCoordinators = bool
}

export function setLoadingAgencys(state, bool) {
    state.isLoadingAgencys = bool
}





