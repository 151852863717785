<template>
  <div>
    <antModal v-model="visible" title="Solicitud de aplicación para boda" :closable="false" :maskClosable="false" :width="750" :centered="true" :footer="null">
      <ValidationObserver ref="weddingAplicationForm" tag="form" v-slot="{ invalid }">
        <b-form @submit.prevent="sendWeddingAplication">
          <b-row>
            <b-col>
              <ValidationProvider rules="required" name="appellido contacto">
                <b-form-group slot-scope="{ valid, errors }">
                  <label> <strong>Nombre del contacto*</strong></label>
                  <b-input
                    placeholder="Nombre del contacto"
                    v-model="NombreContacto"
                    :disabled="isLoadingRequest"
                    :state="errors[0] ? false : (valid ? true : null)"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider rules="required|email" name="correo electrónico">
                <b-form-group slot-scope="{ valid, errors }">
                  <label> <strong>Correo electrónico*</strong></label>
                  <b-input
                    type="email"
                    placeholder="email"
                    v-model="emailContacto"
                    :disabled="isLoadingRequest"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-input>
                  <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider name="idioma" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> <strong>Idioma*</strong></label>
                  <v-select
                    :options="languages"
                    label="name"
                    v-model="idiomaContacto"
                    :disabled="isLoadingRequest"
                    :class="{'is-invalid': !!errors.length}">
                  </v-select>
                  <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button variant="danger" @click="closeWeddingAplicationModal" :disabled="isLoadingRequest">Cerrar</b-button>
            </b-col>
            <b-col>
              <b-button type="submit" variant="success" class="float-right" :disabled="invalid || isLoadingRequest">{{isLoadingRequest ? 'Enviando' : 'Enviar'}} solicitud <b-spinner small v-if="isLoadingRequest"/></b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </antModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props:{
    visible: {
      type: Boolean,
      required: true
    },
    sendRequest: {
      type: Function,
      required: true
    }
  },
  data(){
    return {
      NombreContacto: null,
      emailContacto: null,
      idiomaContacto: null,
      isLoadingRequest: false
    }
  },
  computed:{
    ...mapState('init', ['languages', 'idUser']),
  },
  methods: {
    async sendWeddingAplication(){
      this.isLoadingRequest = true
      if (!!this.NombreContacto && !!this.emailContacto){
        await this.sendRequest({name: this.NombreContacto, email: this.emailContacto, language: this.idiomaContacto?.id, idUser: this.idUser})
        this.isLoadingRequest = false
        this.closeWeddingAplicationModal()
      }
      this.isLoadingRequest = false
    },
    closeWeddingAplicationModal(){
      Object.assign(this.$data, this.$options.data() )
      this.$refs.weddingAplicationForm.reset()

      this.$emit('show-modal-wedding-aplication', false)
    }
  }
}
</script>

<style>

</style>