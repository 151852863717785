import Vue from 'vue'
import { capitalizeFirstLetterString } from '../../helpers/helpers'

export async function fetchHotels(){         
    try {
        
        const response = await Vue.axios({ 
            url:`/catalogs/hotels/`  
        })
        const payload = response && response.data        
        return payload
    } catch (error) {        
        console.log(error)
    } finally {
        console.log('fetchHotels ha terminado (init)')        
    }
}

export async function fetchLanguages(context) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/language/'
        })
        const payload = response && response.data
        context.commit('setLanguages', payload.filter(lang=> lang.id != 3) )        
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchLanguages ha terminado');
    }
}

export async function fetchLocationsScheduler(){         
    try {        
        const response = await Vue.axios({ 
            url:`/catalogs/locaciones/`  
        })
        const payload = response && response.data    
        
        payload.forEach( location => {
            location.name = capitalizeFirstLetterString( location.name)            
        })     
        return payload 
    } catch (error) {        
        console.log(error)
    } finally {        
        console.log('fetchLocationsScheduler ha terminado (init)')
    }
}

export async function fetchGroupTypes(context){         
    try {        
        const response = await Vue.axios({ 
            url:`/catalogs/tipogrupo/`  
        })
        const payload = response && response.data        
        context.commit('setGroupTypes', payload )        
    } catch (error) {        
        context.commit('setInitError', error.message)
    } finally {        
        console.log('La petición para obtener la lista de tipos de grupo se ha terminado (init)')        
    }
}

export async function fetchAgencies(context){         
    try {    
        context.commit('setLoadingAgencys', true )
        const response = await Vue.axios({ 
            url:`/catalogs/agency/`  
        })
        const payload = response && response.data 
        const agencySelected = payload[0].id       
        context.commit('setAgencies', payload )        
        context.commit('setAgencySelected', agencySelected )        
    } catch (error) {        
        context.commit('setInitError', error.message)
    } finally {      
        context.commit('setLoadingAgencys', false )
        console.log('La petición para obtener la lista de agencias se ha terminado (init)')        
    }
}

export async function fetchAgents(context){
    const id = context.rootState.init.agencySelected          
    try {
        
        const response = await Vue.axios({ 
            url:`/catalogs/agent/`,  
            params: {
                ida: id,
            }
        })
        const payload = response && response.data 
              
        context.commit('setAgents', payload )        
               
    } catch (error) {        
        context.commit('setInitError', error.message)
    } finally {    
        console.log('La petición para obtener la lista de agentes se ha terminado (init)')        
    }
}

export async function fetchAgentsByIdAgency(context, idAgency){              
    try {
        const response = await Vue.axios({ 
            url:`/catalogs/agent/`,  
            params: {
                ida: idAgency,
            }
        })
        const payload = response && response.data 
              
        context.commit('setAgents', payload )        
               
    } catch (error) {        
        context.commit('setInitError', error.message)
    } finally {
        console.log('La petición para obtener la lista de agentes para update finished (init)')        
    }
}

export async function fetchCoordinators(context){         
    try {        
        context.commit('setLoadingCoordinators', true)
        const response = await Vue.axios({ 
            url:`/catalogs/coordinadores/`  
        })
        const payload = response && response.data        
        context.commit('setCoordinators', payload )        
    } catch (error) {        
        context.commit('setInitError', error.message)
    } finally {
        context.commit('setLoadingCoordinators', false)
        console.log('La petición para obtener la lista de coordinadores se ha terminado (init)')        
    }
}

export async function fetchPermissionsUser(context, idUser){         
    try {        
       
        const response = await Vue.axios({ 
            url:`/permissionsuser/`  ,
            params: {
                idu: idUser,
            }
        })
        const resp = response && response.data        
        return resp        
    } catch (error) {        
        return error
    } finally {        
        console.log('fetchPermissionsUser end')        
    }
}