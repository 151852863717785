<template>
    <div>    
        <antModal v-model="visible" :title="eventTitle" :closable="false" :maskClosable="false" :centered="true" :footer="null" :width="750">
            <ValidationObserver ref="deleteEvent" tag="form">
                <b-form @submit.prevent="destroyEvent" > 
                    <b-row>
                        <b-col><b>Locación: </b>{{  nameLocation( selectedLocation ) }}</b-col>
                        <b-col><b>Evento: </b> {{ selectedEvent.nombreEvento }}</b-col>
                        <b-col><b>Fecha: </b> {{ selectedEvent.startDate }}</b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col>
                            <label for="textarea" class="font-weight-bold">Indique el motivo</label>                           
                            <ValidationProvider rules="required" name="motivo">
                                <b-form-group slot-scope="{ valid, errors }">
                             
                                <b-form-textarea
                                    id="textarea"
                                    v-model="deleteReason"
                                    placeholder="Describa el motivo del borrado"
                                    rows="3"
                                    max-rows="6"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider> 
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col>
                            <b-button variant="danger" @click="closeModalDeleteEvent">Cerrar</b-button>
                        </b-col>
                        <b-col>
                            <b-button
                                type="submit"
                                variant="danger"
                                class="float-right"
                                :disabled="isDeletingEvent"
                            >Borrar evento</b-button>
                        </b-col>
                    </b-row>                       
                </b-form>
          </ValidationObserver>                                                              
      </antModal>
    </div>
</template>
<script>
  
  
import Vue from 'vue'
import * as moment from 'moment'
import { mapActions, mapState, mapMutations} from 'vuex'
  
export default {
    name: 'EditEvent',
    props:{
        visible: {
            type: Boolean,
            required: true
        }, 
        selectedEvent:{
            type:Object,
            required: true
        }
    },
    mounted(){        
    },
    data() {
        return {
            loading: false, 
            nombreEvento: this.selectedEvent.nombreEvento,    
            deleteReason: '',
            isDeletingEvent: false
        }
    },   
    computed: {        
        ...mapState('init', ['locations','idUser']),                    
        ...mapState('calendar', ['selectedLocation','isOpenModalDeleteEvent','eventsInSelectedDate']),
        eventTitle(){                         
            return `¿Confirma que desea eliminar este evento?`
        },
        formDeleteEvent(){
            return this.$refs.deleteEvent
        },                                 
    },
    methods: {
        moment,
        ...mapActions('calendar',['deleteEvent','getEventsAndTotals']),
        ...mapMutations('calendar',['unsetSelectedEvent','setEventsInSelectedDate','setVisibilityModalDeleteEvent']),
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },
        async destroyEvent(){  
            this.isDeletingEvent = true
            await this.formDeleteEvent.validate() // valido el input textarea required                                  
            if( this.deleteReason === '' ){
                Vue.$toast.open({
                    message: `<strong>Indique la razón del borrado.</strong>`,
                    type: 'error',
                    position:'top-right'
                })
                this.isDeletingEvent = false
                return
            }
            
            const dataEvent = {                 
                user: this.idUser,
                reason: this.deleteReason,
                id: this.selectedEvent.id,
            }
                                                                                            
            const { status } = await this.deleteEvent( dataEvent ) 
            if( status ){                
                Vue.$toast.open({
                    message: `<strong>Evento ${this.selectedEvent?.nombreEvento} borrado.</strong>`,
                    type: 'success',
                    position:'top-right'
                })
                const removeIndex = this.eventsInSelectedDate.map(function(item) { return item.id; }).indexOf( this.selectedEvent.id )
                this.eventsInSelectedDate.splice(removeIndex, 1) 
                this.setVisibilityModalDeleteEvent(false)            
                this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
                this.unsetSelectedEvent() 
                await this.getEventsAndTotals()//calendar module                                         
            }
            //si se cambia  locacion a una diferente de la locación selecionada
            if( this.selectedLocation != this.selectedEvent.locacion ){
                const idEvent = this.selectedEvent.id
                const eventos = this.eventsInSelectedDate.filter( e => e.id !== idEvent ) 
                this.setEventsInSelectedDate( eventos )
            }  
            
            this.isDeletingEvent = false
                          
        },                    
        closeModalDeleteEvent(){
            this.$emit('closeModalDeleteEvent')
        },
               
    },
  }
  
</script>
  