<template>
  <div>
    <!-- :title="eventTitle"  -->
    <b-sidebar id="sidebar-backdrop" right  :backdrop-variant="variant" backdrop shadow width="500px">
        <div>
            <b-container>
                <b-row class="mb-2">
                    <b-col>
                        <h5><strong>Locación: {{ nameLocation( selectedLocation ) }}</strong> </h5>
                        
                    </b-col>
                    <b-col>                        
                        <div class="float-right"><h5><strong>{{eventTitle}}</strong> </h5></div>
                    </b-col>
                </b-row>                
                <b-row>
                    <b-col> 
                                                                       
                        <b-button class="float-right" size="sm" variant="primary" @click="openModalCreateEvent">Nuevo evento</b-button>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col> 
                        <div class="text-center" v-if="isLoadingEvents">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando eventos en fecha seleccionada</strong>
                        </div>                   
                        <div v-if="eventsInSelectedDate.length && !isLoadingEvents">                            
                            <Event
                                v-for="(event, index) in eventsInSelectedDate" :key="index"
                                :event="event"
                                @edit-event="editEvento"
                                @confirm-delete-event="openModalDeleteEvent"
                                @log-event="openModalLogsEvent"
                            />
                        </div>                        
                        <b-alert show variant="danger" v-if="!eventsInSelectedDate.length && !isLoadingEvents">Aún sin eventos para esta fecha</b-alert>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </b-sidebar>

    <EditEvent
        v-if="selectedEvent"
        :visible="isOpenModalEditEvent"
        :selectedEvent="selectedEvent"         
        @closeModalEditEvent="closeModalEditEvent"
    /> 

    <DeleteEvent
        v-if="selectedEvent"
        :visible="isOpenModalDeleteEvent"
        :selectedEvent="selectedEvent"         
        @closeModalDeleteEvent="closeModalDeleteEvent"
    />

    <LogsEvent  
        v-if="selectedEvent"         
        :visible="isOpenModalLogsEvent"
        :selectedEvent="selectedEvent"  
        :isLoadingLogs="isLoadingLogs" 
        :logs="logs"      
        @closeModalLogsEvent="closeModalLogsEvent"
    />    

  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import * as moment from 'moment'
import Event from '@/components/events/Event'
import EditEvent from '@/components/events/EditEvent'
import DeleteEvent from '@/components/events/DeleteEvent'
import LogsEvent from '@/components/events/LogsEvent'


export default {
    name: 'SidebarDetails',
    components : {
        Event,
        EditEvent,
        DeleteEvent,
        LogsEvent
    },
    props:{        
        selectedDate: {
            type: Object,
            required: true
        }
    },    
    data() {
        return {
            loading: false,
            type:'success',
            hour: moment().hour(10).minute(0),
            content: '',
            variant: 'info',
            borders: {success: '#5cb85c'},
            isLoadingLogs: false,
            logs: []    
        }
    },
    computed: {
        ...mapState('calendar', ['eventsInSelectedDate','selectedEvent','selectedLocation','isOpenModalEditEvent', 'isOpenModalDeleteEvent','isOpenModalLogsEvent','isLoadingEvents','groups']),
        ...mapState('init', ['locations']),
        eventTitle(){
            return `Fecha: ${this.selectedDate.format('DD/MM/YYYY')}`
        },
       
    },
    methods: {
        moment,      
        ...mapActions('calendar',['fetchGroupsScheduler','getEventsAndTotals','updateEvent','deleteEvent','fetchGroupById','fetchLogsEvent']),
        ...mapActions('init',['fetchLocationsScheduler']),
        ...mapMutations('calendar',['setSelectedEvent','unsetSelectedEvent','setVisibilityModalEditEvent', 'setVisibilityModalDeleteEvent', 'setVisibilityModalLogsEvent','setGroupsData']),
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },
        openModalCreateEvent(){
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.$emit('openModalCreateEvent')
        },
        async editEvento( evento ){
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.setSelectedEvent(evento)
            this.setVisibilityModalEditEvent( true )                                             
            this.setGroupsData([])
            const payload = { idGroup: evento.grupo.id } 

            if( !this.groups.length ){                
                await this.fetchGroupById( payload ) // si state groups está vacío obtengo datos del grupo para ponerlos en state groups
            }
            if( this.groups.length ){
                const grupo = this.groups.find( group => group.id == payload.idGroup )
                if( !grupo ){ //sino se encuentra el grupo en el state groups
                    await this.fetchGroupById( payload ) // solicito al back la info por id que corresponda 
                }                                
            }
        },        
        closeModalEditEvent(){
            this.setVisibilityModalEditEvent(false)            
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.unsetSelectedEvent()
        },
        closeModalDeleteEvent(){
            this.setVisibilityModalDeleteEvent(false)            
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.unsetSelectedEvent()
        },
        closeModalLogsEvent(){
            this.setVisibilityModalLogsEvent(false)            
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')   
            this.unsetSelectedEvent()
        },
        async openModalDeleteEvent( evento ){      
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.setSelectedEvent(evento)
            this.setVisibilityModalDeleteEvent( true )            
        },
        async openModalLogsEvent( evento ){  
            // console.log(evento)
            this.logs = []
            this.isLoadingLogs = true
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.setSelectedEvent(evento)
            this.setVisibilityModalLogsEvent( true ) 
            const  response = await this.fetchLogsEvent( evento.id )
            if( response.status){
                this.setLogsEvent( response.logs )                
            }
            this.isLoadingLogs = false                       
        },
        setLogsEvent( logsData,  ){
            const logs = logsData.filter(log => log.system === 'Calendario Bodas')
            //ordeno del mas reciente al más antigup
            logs.sort((a, b) => new Date(b.createdate) - new Date(a.createdate));
            logs.forEach( log  => {
                log.previousData = log.previousData ? JSON.parse(log.previousData) : null            
                log.actualData = log.actualData ? JSON.parse(log.actualData) : null
                log.createDateHuman = log.createdate ? this.humanDate( log.createdate ): ''                
                log.timeAgo = log.createdate ? this.humanDateRelative( log.createdate ).toUpperCase(): ''                

                if( log.previousData ){
                    delete log.previousData._state 
                }
                if( log.actualData ){
                    delete log.actualData._state 
                }                
            })            
            this.logs = logs
        },
        humanDate( dateRaw ){
            const date = new Date(dateRaw)

            const humanReadable = date.toLocaleString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            })
            return humanReadable
        },
        humanDateRelative(dateRaw){
            const date = new Date(dateRaw);
            const now = new Date();
            const diffInSeconds = Math.floor((now - date) / 1000);

            const intervals = [
                { label: "year", seconds: 31536000 },
                { label: "month", seconds: 2592000 },
                { label: "week", seconds: 604800 },
                { label: "day", seconds: 86400 },
                { label: "hour", seconds: 3600 },
                { label: "minute", seconds: 60 },
                { label: "second", seconds: 1 }
            ];

            for (const interval of intervals) {
                const count = Math.floor(diffInSeconds / interval.seconds);
                if (count >= 1) {
                    return new Intl.RelativeTimeFormat('es', { numeric: 'auto' }).format(-count, interval.label);
                }
            }

            return "Hace poco";
        }

    },
}
</script>
<style>
.card-body {
    padding: .5rem!important;
}
.button-small{
    padding: 0.15rem 0.2rem!important;
}
</style>