export default {
    locations:[],
    groupTypes:[],
    hotels: [],
    agencies:[],
    agents: [],
    coordinators:[],
    events:[],
    languages:[],
    selectedHotelFilter: 'all',
    selectedLocationFilter: 'all',
    idUser: null,
    permissionsUser: [],
    agencySelected: null,
    idUser: null,
    initError: null,
    isLoadingLocations: false,
    isLoadingGroupTypes: false,
    isLoadingCoordinators: false,
    isLoadingAgencys: false,
}